<template>
  <div class="layout">
    <van-index-bar :index-list="A_Z">
      <div v-for="(item, index) in brandList" :key="index">
        <van-index-anchor :index="item.index" />
        <div class="cell">
          <div
            class="cell-info"
            v-for="(row, index) in item.list"
            :key="index"
            @click="toGood(row)"
          >
            <img :src="row.logo" alt="" />
            <p class="cell-name">{{ row.brandName }}</p>
          </div>
        </div>
      </div>
    </van-index-bar>
    <!-- <van-index-bar>
          <van-index-anchor v-for="(item) in A_Z" :key="item" :index="item" />
          <van-cell v-for="(item, index1) in brandList" :key="index1" :title="item.brandName" />
          <div v-for="(item, index1) in brandList" :key="index1">
            <p >{{item.brandName}}</p>
          </div>
        </van-index-bar> -->
  </div>
</template>

<script>
export default {
  name: "mine",

  data() {
    return {
      brandList: [],
      A_Z: [],
    };
  },

  mounted() {},
  created() {
    this.getBrand();
  },

  methods: {
    async getBrand() {
      await this.$API.brand
        .BrandInitialsList({
          object: {
            brandInitials: "",
          },
        })
        .then((res) => {
          if (res.data.code != "SUCCESS") return false;
          this.A_Z = Object.keys(res.data.data).map((keys) => {
            return keys.toUpperCase();
          });
          let arr = Object.values(res.data.data);
          this.brandList = this.A_Z.map((index, i) => ({
            index,
            list: arr[i],
          }));
          // this.A_Z.map((v, i) => {
          //   const obj = {};
          //   obj[v] = arr[i];
          //   arr.push(obj);
          // })
        });
    },
    toGood(data) {
      this.$router.push({
        path: "goodsList",
        query: {
          brandId: data.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
    //   max-width: 380px;
    // margin: 0 auto;
  // height: 100vh;
  background: #f5f5f5;
  padding-top: 12px;
}
.cell {
  display: flex;
  flex-wrap: wrap;
}
.cell-info {
  text-align: center;
  margin: 4px;
  padding: 8px 0;
  width: 110px;
  background: #fff;
  // padding: 8px 30px;
  img {
    width: 50px;
    height: 36px;
    object-fit:contain;
  }
  p {
    margin-top: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>